import { Grid } from '@mui/material';
import Zenown from '../../assets/reader/Logo.png';

export const Header = (props: any) => {
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="start"
      justifyContent="center"
      textAlign={'center'}
      style={{
        height: 200,
        padding: '30px 20px',

        backgroundPosition: 'center',
      }}
    >
      <Grid item xs={12} textAlign={'left'}>
        <img src={Zenown} style={{ height: '36px', width: '109px' }} />
      </Grid>
      <Grid item xs={12} textAlign={'center'} sx={{ marginTop: '31px' }}>
        <h1>Track & trace code reader demo</h1>
      </Grid>
    </Grid>
  );
};
