import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Barcode, BarcodePicker as ScanditSDKBarcodePicker, BarcodePicker, configure } from "scandit-sdk";
import { SingleImageModeSettings } from "scandit-sdk/build/main/lib/singleImageModeSettings";
import { SearchArea } from "scandit-sdk/build/main/lib/searchArea";
import { Scanner } from "scandit-sdk/build/main/lib/scanner";
import { ScanSettings } from "scandit-sdk/build/main/lib/scanSettings";
import { Camera } from "scandit-sdk/build/main/lib/camera";
import { CameraSettings } from "scandit-sdk/build/main/lib/cameraSettings";
// @ts-ignore
import ScanditBarcodeScanner from "scandit-sdk-react";


export interface BarcodePickerProps {
  onScan?: (result: any)=> void,
  onError?:  (error: any)=> void,

  visible?: boolean;
  singleImageModeSettings?: SingleImageModeSettings;
  playSoundOnScan?: boolean;
  vibrateOnScan?: boolean;
  scanningPaused?: boolean;
  guiStyle?: BarcodePicker.GuiStyle;
  videoFit?: BarcodePicker.ObjectFit;
  cameraRecoveryText?: string;
  laserArea?: SearchArea;
  viewfinderArea?: SearchArea;
  scanner?: Scanner;
  scanSettings?: ScanSettings;
  enableCameraSwitcher?: boolean;
  enableTorchToggle?: boolean;
  enableTapToFocus?: boolean;
  enablePinchToZoom?: boolean;
  accessCamera?: boolean;
  camera?: Camera;
  cameraType?: Camera.Type;
  cameraSettings?: CameraSettings;
  targetScanningFPS?: number;
}


const useStyles = makeStyles({
  scanner:{
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    margin: "auto",
    maxWidth: "1280px",
    maxHeight: "80%",
  }
});

export const BarcodePickerElem  = (props: BarcodePickerProps) => {

  const classes = useStyles();
/*
  const ref = useRef<any> (null);

  const [barcodePicker, setBarcodePicker]= useState<BarcodePicker>();


  console.log('props ', props);


  useEffect(()=>{
    // Configure the library and activate it with a license key
    const configurationPromise = configure(
      "AWBR8T8PHZNPG18CdDDPp6YARpP2P9ZpIx9hzD5WN+3eWet/TXChh753HAVDfoC97lzitVJEDeEgV/NnWkdZfKIqRHgmavGGl0w+eWJL2L0iBnJHKzTLs58SD8VhMRFCcJblkdZCv16Bsm58Sv63SbFueXnSfKUAveBFTcc+EGrXL/xYD/L+Lwb2ej9thMxmdrO1ZwYMrUvfaR5MXBGyWC2AKicgxFaBc3ub/APQ8DobOzbCd3K2k5MRns/qlWSIPZ7hmx8lXNp/QESbvIO+WPnAi7soSQIAzv5HKanEjuqlKIz3+QYNUDiuQA3jfvuzIhOCX4+Br/KuRUR5R/G/0WKqvr1wEA09NNVAZbDPmeP0tEhlzgZsXfJCcf1SpSx/eAs9vuWY2XV/AW5huBWBgBB0KxGHtikkGr7K2n1qbC0h4B4HiAbQpSj/tb7/50fty6nj7anlTSEdS4FNXM2gIIcA8KIb/X1kg64mPorCAFHK8ojZ9zG08u8VcGATEqT9KHWrrUCLh5UReWhpIm/ZZNk3evq9kYwFLTCoe0FudzBqItmD1OYmisjalMXGWTMKz4YRf4mYfDHPN8B58MS/KWEn36dITiT+9JBOyJTkB0u5WI+oyvPvvYA5Ttnfxd/mjNl3nmX+DPp2MVoaVd8hIRwXwNdesWZPspE8TTL6eUOlExOtSTBoxTjlQJPlZqA1pGvkJDoWLg3np5z6C06PqZdxdUhPp1dKtps6gDTzALZ9v9CDz97wdtmz+0MokxBnylE0tokrlZxNu88+88Us5wqZuoQ7gr+4hkBTV0GfTA=="
    )
      .then((result) => {
        configurationPromise.then(() => {
          const propsSettings = {
            ...props,
            guiStyle: BarcodePicker.GuiStyle.VIEWFINDER
          }
          ScanditSDKBarcodePicker.create(ref.current, propsSettings)
            .then((barcodePicker) => {
              setBarcodePicker(barcodePicker)
              console.log('barcodePicker ', barcodePicker)
              if (props.onScan != null) {
                console.log('scan ')
                barcodePicker.on("scan", props.onScan);
              }
              if (props.onError != null) {
                console.log('onError ')
                barcodePicker.on("scanError", props.onError);
              }
            });
        });
      })
      .catch((error) => {
        alert(error);
      });
  }, [])

  useEffect(()=> {
    if (barcodePicker && props.scanSettings) {
      console.log('props.scanSettings ', props.scanSettings)
      barcodePicker.applyScanSettings(props.scanSettings);
    }

    if (barcodePicker && props.visible) {
      console.log('props.visible ', props.visible)
      barcodePicker.setVisible(props.visible);
    }
  }, [barcodePicker])

  return <div ref={ref} className={classes.scanner}> <p></p></div>;

  */

  const licenseKey = "AWBR8T8PHZNPG18CdDDPp6YARpP2P9ZpIx9hzD5WN+3eWet/TXChh753HAVDfoC97lzitVJEDeEgV/NnWkdZfKIqRHgmavGGl0w+eWJL2L0iBnJHKzTLs58SD8VhMRFCcJblkdZCv16Bsm58Sv63SbFueXnSfKUAveBFTcc+EGrXL/xYD/L+Lwb2ej9thMxmdrO1ZwYMrUvfaR5MXBGyWC2AKicgxFaBc3ub/APQ8DobOzbCd3K2k5MRns/qlWSIPZ7hmx8lXNp/QESbvIO+WPnAi7soSQIAzv5HKanEjuqlKIz3+QYNUDiuQA3jfvuzIhOCX4+Br/KuRUR5R/G/0WKqvr1wEA09NNVAZbDPmeP0tEhlzgZsXfJCcf1SpSx/eAs9vuWY2XV/AW5huBWBgBB0KxGHtikkGr7K2n1qbC0h4B4HiAbQpSj/tb7/50fty6nj7anlTSEdS4FNXM2gIIcA8KIb/X1kg64mPorCAFHK8ojZ9zG08u8VcGATEqT9KHWrrUCLh5UReWhpIm/ZZNk3evq9kYwFLTCoe0FudzBqItmD1OYmisjalMXGWTMKz4YRf4mYfDHPN8B58MS/KWEn36dITiT+9JBOyJTkB0u5WI+oyvPvvYA5Ttnfxd/mjNl3nmX+DPp2MVoaVd8hIRwXwNdesWZPspE8TTL6eUOlExOtSTBoxTjlQJPlZqA1pGvkJDoWLg3np5z6C06PqZdxdUhPp1dKtps6gDTzALZ9v9CDz97wdtmz+0MokxBnylE0tokrlZxNu88+88Us5wqZuoQ7gr+4hkBTV0GfTA=="
  const getScanSettings = () => {
    return new ScanSettings(
      { enabledSymbologies: [Barcode.Symbology.DATA_MATRIX] });
  };

  const getSingleImageModeSettings = () => {
    return {
      desktop: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
      mobile: {
        usageStrategy: SingleImageModeSettings.UsageStrategy.FALLBACK,
      },
    };
  };


  return <ScanditBarcodeScanner
    licenseKey={licenseKey}
    engineLocation="https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build"
    preloadBlurryRecognition={true}
    preloadEngine={true}
    onReady={console.log}
    onScan={console.log}
    onScanError={console.log}
    onSubmitFrame={console.log}
    onProcessFrame={console.log}
    scanSettings={getScanSettings()}
    paused={false}
    /*️
      ⚠️ Make sure to keep accessCamera and paused synchronized in a sensible way, as resuming scanning accesses
      the camera, so your state might become outdated.
      For example, set accessCamera to true whenever scanning is resumed.
    */
    accessCamera={true}
    camera={undefined}
    cameraSettings={undefined}
    enableCameraSwitcher={true}
    enablePinchToZoom={true}
    enableTapToFocus={true}
    enableTorchToggle={true}
    guiStyle={BarcodePicker.GuiStyle.LASER}
    laserArea={{ x: 0, y: 0, width: 1, height: 1 }}
    playSoundOnScan={true}
    targetScanningFPS={30}
    vibrateOnScan={true}
    videoFit={BarcodePicker.ObjectFit.CONTAIN}
    visible={true}
    viewfinderArea={{ x: 0, y: 0, width: 1, height: 1 }}
    zoom={0}
    // only set on component creation, can not be changed afterwards
    cameraType={Camera.Type.BACK}
    singleImageModeSettings={getSingleImageModeSettings()}
  />
}

export default BarcodePickerElem;
