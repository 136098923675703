import StartImageReader from '../../assets/reader/home_image.png';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { namespaces } from '@zenown-insurance/i18n-service';

export interface TitleSectionProps {
  showIdentityReader: boolean;
}

export const TitleSection = (props: TitleSectionProps) => {
  const { t } = { t: (s: any) => s };
  const { showIdentityReader } = props;

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="start"
      justifyContent="center"
      textAlign={'center'}
      sx={{ marginTop: '42px' }}
    >
      <Grid item xs={12} textAlign={'center'}>
        <img
          src={StartImageReader}
          style={{ width: '270px', height: '290px' }}
        />
      </Grid>
    </Grid>
  );
};
