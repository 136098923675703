import { Container, Grid, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';
import styles from '../../containers/zenown-reader.module.scss';
import ZenOwnLogo from '../../assets/reader/ZenOwn logo.svg';

export const Footer = (props: any) => {
  return (
    <>
      <Typography
        variant="caption"
        sx={{
          fontSize: '10px',
          fontStyle: 'Semibold S',
          marginBottom: '30px',
          marginTop: '87px',
        }}
      >
        <span
          style={{
            fontWeight: '500',
          }}
        >
          {' '}
          Powered by{' '}
        </span>
        <Link href="#" underline="hover">
          ZenOwn
        </Link>
      </Typography>
    </>
  );
};
