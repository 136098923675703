import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './app/app';

ReactDOM.render(
  <React.Suspense fallback={<></>}>
    <StrictMode >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  </React.Suspense>,
  document.getElementById('root')
);
