import { Box, Container, Paper, useMediaQuery } from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export type ReaderLayoutProps = {};

export const ReaderLayout: React.FC<ReaderLayoutProps> = ({ children }) => {
  //TODO: use theme lyout and use the theme variable for switchign themes in teh future
  const matches = useMediaQuery('(min-width:500px)');
  return (
    <>
      <Paper
        sx={{
          minWidth: '100%',
          backgroundColor: matches ? '#F6F4F0' : '#F6F4F0',
          borderRadius: '0px',
          maxWidth: '378px !important',
          width: 'auto',
          boxShadow: 'none',
          paddingTop: '30px',
          paddingBottom: '30px',
          height: '100% ',
        }}
      >
        <Container
          sx={{
            position: 'sticky',
            maxWidth: '378px !important',
            width: 'auto',
            //maxHeight: '708px',
            //height: '708px',
            minHeight: '-webkit-fill-available',
            height: 'auto',
            bgcolor: '#F6F4F0',
            marginInline: 'auto',

            paddingInline: '15px',
            // marginBottom: '21px',
            //marginTop: '29.65px',
          }}
        >
          {children}
        </Container>{' '}
      </Paper>
    </>
  );
};

export default ReaderLayout;
