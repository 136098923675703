import { Grid } from '@mui/material';
import { Header } from '../components/zenown-reader/Header';
import { TitleSection } from '../components/zenown-reader/TitleSection';
import { UploadSection } from '../components/zenown-reader/UploadSection';
// import { LanguageSection } from "../components/zenown-reader/LanguageSection";
import { QuoteSection } from '../components/zenown-reader/QuoteSection';
import { HowWorksSection } from '../components/zenown-reader/HowWorksSection';
import { useState } from 'react';
import SubmittingInvoice from './zenown-glasses/submittingInvoice';
import HowDoesWorks from './zenown-reader/howDoesWorks';
import ReadRegisterProcess from './zenown-reader/read_register_process';
import Read_register_process_invoice from './zenown-reader/read_register_process_invoice';
import ReadRegisterInvoiceProcess from './zenown-reader/read_register_process_invoice';
import { Footer } from '../components/footer/Footer';
import ReaderLayout from '../components/zenown-reader/ReaderLayout';

export interface ZenownReaderContainerProps {
  showIdentityReader: boolean;
}
export function ZenownReaderContainer(props: ZenownReaderContainerProps) {
  const { showIdentityReader } = props;
  const [step, setStep] = useState<'home' | 'upload' | 'how'>('home');

  const [showHowWorks, setShowHowWorks] = useState(false);

  const [filesToSubmit] = useState([]);

  if (step === 'upload') {
    return (
      <ReadRegisterProcess
        onClose={() => setStep('home')}
        onHow={() => setStep('how')}
        filesToSubmit={filesToSubmit}
      />
    );
  }
  if (step === 'how') {
    return <HowDoesWorks onClose={() => setStep('upload')} />;
  }

  return (
    <>
      {' '}
      <Grid
        container
        direction="column"
        justifyContent="start"
        alignItems="center"
        spacing={0}
        style={{ width: '100%' }}
      >
        <Grid item xs={12}>
          <Header />
          <TitleSection showIdentityReader={showIdentityReader} />{' '}
          <QuoteSection
            onSubmit={() => setStep('upload')}
            showIdentityReader={showIdentityReader}
          />
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '87px' }}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
}

export default ZenownReaderContainer;
