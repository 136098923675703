import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Link,
  Slide,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TransitionProps } from '@mui/material/transitions';
import ReactCodeInputFields from '../../../components/react-code-input-fields/react-code-input-fields';
import { usePrevious } from '../../../utils';
import { DialogContainer } from '../../../components/dialog/dialogContainer';
import { OfferSection } from '../../../components/zenown-reader/OfferSection';
import Camera from '../../../assets/reader/camera.svg';
import ArrowLeft from '../../../assets/reader/arrow-left.svg';
import Check from '../../../assets/reader/tick-circle.svg';
import { BarcodePickerElem } from '@zenonw/ui';
import { ScanSettings } from 'scandit-sdk';
import DataMatrixReaderFromImage from '../../../components/data-matrix-reader/data-matrix-reader-from-image';
import axios from 'axios';
import ErrorMessage from '../../../components/error-message/ErrorMessage';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const useStyles = makeStyles({
  text: {
    color: '#9CA0AB',
    fontSize: 14,
    letterSpacing: -0.3,
    display: 'block',
    lineHeight: '110%',
  },
});

export interface RegisterStep1Props {
  values: any;
  onChange: (input: any, evt: any) => void;
  onPrevEvt: () => void;
  onHow: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const RegisterStep1 = (props: RegisterStep1Props) => {
  const classes = useStyles();
  const { values, onChange, onPrevEvt } = props;
  const fileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState();
  const [progress, setProgress] = useState(0);
  const [showMatrixReader, setShowMatrixReader] = useState(false);

  const [showHowWorks, setShowHowWorks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isValidCode, setIsValidCode] = useState(true);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [serialNumber, setSerialNumber] = useState<any[]>([]);
  const [serialNumberFull, setSerialNumberFull] = useState('');
  const [error, setError] = useState(false);
  const initProc = () => {
    setError(false);
    setFile(undefined);
    setSerialNumberFull('');
  };
  const submitFile = async () => {
    let file64: any;
    async function getBase64(file: any) {
      var filereader = new FileReader();
      filereader.readAsDataURL(file);
      filereader.onload = async function (evt: any) {
        file64 = evt?.target?.result;

        setIsLoading(true);
        let binaryFile: any;

        /*await fetch(file64).then(
          async (res) => (binaryFile = await res.blob())
        );*/
        axios
          .post('https://ocr-stage.zenown.com/', file64, {
            timeout: 60000,
          })
          .then((res: any) => {
            if (res?.data?.code) {
              const arrayChars = res.data.code.split(/\s/g);
              console.log(arrayChars);
              if (
                arrayChars.length === 4 &&
                arrayChars.every((x: any) => x.length === 3)
              )
                setSerialNumberFull(res?.data?.code.replace(/\s/g, ''));
              else throw 'unvalid code';
            } else throw 'unvalid code';
            /*const id = res['id'];
           axios
              .post('https://3.75.113.246:80/', id, {
                timeout: 60000,

                onUploadProgress: (progressEvent: any) => {
                  const { loaded, total } = progressEvent;
                  let percent = Math.floor((loaded * 100) / total);

                  setProgress(percent);
                  console.log('progress: ', progress);
                },
              })
              .then((result: any) => {
                console.log('result ', result);
                setSerialNumberFull(
                  result.prediction.reduce(
                    (prev: any, curr: any) => prev + curr.label,
                    ''
                  )
                );
              })
              .catch((err) => {
                console.log('err', err);
                setError(true);
              });*/
          })
          .catch((err) => {
            console.log('err', err);
            setError(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
    }
    await getBase64(file);
  };
  useEffect(() => {
    if (file) {
      submitFile();
    } else {
      setError(false);
    }
  }, [file]);
  const handleChange = async (e: any) => {
    initProc();
    const file = e.target.files[0];
    setFile(e.target.files[0]);
    setSerialNumberFull('');

    /*if (e.target.files && e.target.files.length > 0) {
      setIsLoading(true);
      setHasError(false);
      console.log('file ', file);
      const uuid = uuidv4();

      const formData = new FormData();
      formData.append('uuid', uuid);
      formData.append('file', file);

      try {
        const uploadFile = await uploadServices.readQRCode(formData);

        if (uploadFile.data && uploadFile.data.text) {
          props.onSuccess(uploadFile.data.text);
        } else if (uploadFile.data && uploadFile.data.code) {
          props.onSuccess(uploadFile.data.code);
        } else {
          // props.onError()
          setHasError(true);
        }
      } catch (e) {
        // props.onError()
        setHasError(true);
      }

      setIsLoading(false);
    }*/
  };
  const [showManualInput, setShowManualInput] = useState(false);

  const onClickEvt = (evt: any) => {
    onChange('sn', serialNumberFull);
  };

  const onClickPrevEvt = () => {};
  const onHowWorksClickEvt = () => {
    setShowHowWorks(!showHowWorks);
  };

  const onCodeSuccess = (code: string) => {
    // console.log('code ', code);
    const cleanCode = code.replace(/\u001d/g, ''); // removes Hexadecimal character from the stringthe code
    const patternLengths = [2, 14, 2, 12, 3, 11]; //
    const extracted = patternLengths.map(
      (
        (p) => (i) =>
          cleanCode.slice(p, (p += i))
      )(0)
    );
    const regex = /\w{3}\s\w{3}\s\w{3}\s\w{3}/m;

    if (
      extracted.length === 6 &&
      extracted[0] === '01' &&
      extracted[2] === '21' &&
      extracted[3].length === 12 &&
      extracted[4] === '240'
    ) {
      // console.log('VALID CODE ', extracted[3]);
      const splitCodeVal = splitCode(extracted[3]);
      // console.log('VALID splitCode ', splitCodeVal);
      setSerialNumber(splitCodeVal);
      setSerialNumberFull(extracted[3]);
      setShowMatrixReader(false);
      setIsValidCode(true);
      setShowManualInput(true);
    } else if (code.length === 15 && code.match(regex)) {
      // console.log(' code', code);
      const splitCodeVal = splitCode(code.replace(/\s/g, ''));

      setSerialNumber(splitCodeVal);
      setSerialNumberFull(code.replace(/\s/g, ''));
      setShowMatrixReader(false);
      setIsValidCode(true);
      setShowManualInput(true);
    }

    // 01 07623900404513 -14
    // 21 TQ3LWT3FZUTL - 12
    // 240 DK000901.03 - 11
  };

  const onCodeError = () => {
    setShowMatrixReader(false);
    setIsValidCode(false);
  };

  const resetCode = () => {
    setShowMatrixReader(true);
    setIsValidCode(true);
  };

  const onOpenScanner = () => {
    setShowMatrixReader(true);
  };
  const onCloseScanner = () => {
    setShowMatrixReader(false);
  };

  const chunkString = (str: string, length: number) => {
    return str.match(new RegExp('.{1,' + length + '}', 'g'));
  };

  const splitCode = (codeString: string) => {
    const chunck = chunkString(codeString, 4);
    if (!chunck) return [];
    let arrayObj: any[] = [];
    chunck.forEach((e) => {
      arrayObj.push(e);
    });
    // console.log('chunck ', arrayObj);
    return arrayObj;
  };

  const onFieldsInputEvent = (inputField: string) => {
    setSerialNumberFull(inputField);
  };
  const prevCount: string = usePrevious(serialNumberFull) || '';

  useEffect(() => {
    if (prevCount === undefined) {
      return;
    }
    if (serialNumberFull.length === 12) {
      setIsValidCode(true);
      setAllowSubmit(true);
    } else if (serialNumberFull.length === 0) {
      setIsValidCode(true);
      setAllowSubmit(false);
    } else if (serialNumberFull.length < 12 && prevCount.length === 12) {
      setIsValidCode(false);
      setAllowSubmit(false);
    }
  }, [serialNumberFull]);

  if (showMatrixReader) {
    return (
      <Grid container>
        <Grid item xs={12}>
          {/*
            <DataMatrixReader DataMatrixReaderFromCamera
            useDefaultCamera={false}
            onClose={onCloseScanner}
            onSuccess={onCodeSuccess}/>

<BarcodePickerElem
            playSoundOnScan={true}
            vibrateOnScan={true}
            scanSettings={
              new ScanSettings({
                // enabledSymbologies: [ Barcode.Symbology.DATA_MATRIX, Barcode.Symbology.QR],
                codeDuplicateFilter: 1000,
                blurryRecognition: true,
                gpuAcceleration: true
              })
            }
            onScan={scanResult => {
              console.log('scanResult ', scanResult)
            }}
            onError={error => {
              console.error(error.message);
            }}
          />
             */}
          <DataMatrixReaderFromImage
            onClose={onCloseScanner}
            onError={onCodeError}
            onSuccess={onCodeSuccess}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
          width: 'calc(100% + 30px)',
          left: '-15px',
        }}
        open={Boolean(isLoading)}
      >
        <CircularProgress color="inherit" sx={{ marginRight: '5px' }} />{' '}
        Processing ...
      </Backdrop>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        style={{ minHeight: '100%' }}
        spacing={0}
      >
        <Grid item xs={12}>
          <h1>Scan code</h1>
          <p style={{ marginTop: '15px' }}>
            Scan the code number found underneath the pack or bundle with your
            phone’s camera:
          </p>
          <a>
            {' '}
            <span
              onClick={() => props.onHow()}
              style={{
                cursor: 'pointer',

                fontWeight: 'bold',
                marginTop: '15px',
                textDecoration: 'underline',
              }}
            >
              <b>Where to find it?</b>
            </span>
          </a>
        </Grid>
        <Grid item xs={12}></Grid>

        <Grid item xs={12} style={{ paddingTop: 0, marginTop: '15px' }}>
          <div
            style={{
              height: '250px',
              border: '1px solid #E5E5E5',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            {' '}
            {file ? (
              <div>
                <IconButton
                  size="small"
                  onClick={() => initProc()}
                  sx={{
                    position: 'absolute',
                    float: 'right',
                    width: '26px',
                    height: '26px',
                    right: '0px',
                    marginRight: '5px',
                    marginTop: '3px',
                  }}
                >
                  <CloseOutlinedIcon />
                </IconButton>{' '}
                <img
                  style={{
                    maxHeight: '250px',
                    maxWidth: '300px',
                    objectFit: 'cover',
                    margin: 'auto',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                  }}
                  src={URL.createObjectURL(file)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <input
            ref={fileRef}
            onChange={handleChange}
            multiple={false}
            type="file"
            accept={'image/*'}
            hidden
            //@ts-ignore
            //capture="filesystem"
          />
          <Button
            style={{
              fontFamily: 'Satoshi-Variable',
              borderRadius: 20,
              border: '2px #5FCED0 solid',
              height: 55,
              color: '#33303C',
              backgroundColor: 'transparent',
              marginBottom: '30px',
              marginTop: '20px',
            }}
            variant={'contained'}
            onClick={(e: any) => {
              if (fileRef.current) {
                fileRef.current.click();
              }
            }}
          >
            <img src={Camera} style={{ paddingRight: 10 }} />
            Scan the code
          </Button>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: 20, textAlign: 'center' }}>
          {error ? (
            <div style={{ marginBottom: '10px' }}>
              {' '}
              <ErrorMessage message="Oops, something went wrong, please try again" />
            </div>
          ) : (
            <></>
          )}
          <ReactCodeInputFields
            type="text"
            forceUppercase={true}
            isValid={error ? undefined : true}
            fieldsSize={3}
            fields={4}
            inputMode={'text'}
            value={serialNumberFull}
            name={'sn'}
          />
          <br />
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: '30px', marginTop: '20px' }}>
          <Button
            style={{
              fontFamily: 'Satoshi-Variable',
              border: 'none',
              boxShadow: 'none',
              textTransform: 'none',
              height: 57,
              padding: '0 10px',
              color: '#33303C',
            }}
            onClick={onPrevEvt}
            variant={'outlined'}
          >
            <img src={ArrowLeft} style={{ paddingRight: 10 }} /> Previous
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
