// custom hook for getting previous value
import { useState, useEffect, useRef } from "react";

export const usePrevious = (value: any) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  },[value]);

  return ref.current;
}


interface IUseInterval {
  (callback: () => void, interval: number): void;
}

export const useInterval: IUseInterval = (callback, interval: number) => {
  const savedCallback = useRef<(() => void) | null>(null);
  // After every render, save the latest callback into our ref.
  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (interval < 0) {
      return
    }
    let id = setInterval(tick, interval);
    return () => clearInterval(id);
  }, [interval]);
};
