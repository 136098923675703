import { Route, Switch, Redirect } from 'react-router-dom';
import { ZenownReaderContainer } from '@zenown-reader/zenown-reader';
import { useLocation } from 'react-router-dom';
import ZenownTestingContainer from '../../../../libs/ui/src/lib/containers/zenown-testing_container';
import ReaderLayout from 'libs/ui/src/lib/components/zenown-reader/ReaderLayout';

export function App() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  //const showIdentityReader = query.get('identityReader') && query.get('identityReader') === 'show'? true: false;

  const showIdentityReader = true;

  return (
    <>
      <Switch>
        {' '}
        <Route path={'/'}>
          <ReaderLayout>
            <ZenownReaderContainer showIdentityReader={showIdentityReader} />
          </ReaderLayout>
        </Route>
        <Route path={'*'}>
          <Redirect from={'*'} to="/" />
        </Route>
      </Switch>
    </>
  );
}

export default App;

{
  /* <>
        <NxWelcome title="zen-reader" />
      <div />


    START: routes
  {/* These routes and navigation have been generated for you
  {/* Feel free to move and update them to fit your needs *}
      <ReadFromCamera />

      <ReadFromImage />
      <ReadFromCameraQRCode />
      <br />
      <hr />
      <br />
      <div role="navigation">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/page-2">Page 2</Link>
          </li>
        </ul>
      </div>
      <Route
        path="/"
        element={() => (
          <div>
           <ReadFromCamera />
          </div>
        )}
      />
      <Route
        path="/page-2"
        element={() => (
          <div>
            <Link to="/">Click here to go back to root page.</Link>
          </div>
        )}
      />
      {/* END: routes *
  </>
  */
}
