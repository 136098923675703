import React, { useEffect, useRef, useState } from "react";
import {
  BarcodeFormat,
  BrowserDatamatrixCodeReader,
  BrowserMultiFormatReader, BrowserQRCodeReader,
  ChecksumException, DecodeHintType,
  FormatException,
  NotFoundException,
  VideoInputDevice
} from "@zxing/library";
import { Button, Grid } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import datamatrix from '../../assets/reader/DMtx.jpg'
import { makeStyles } from "@mui/styles";

import { configure, BarcodePicker as ScanditSDKBarcodePicker } from "scandit-sdk";
import { UploadService } from "@zenown-insurance/services/requests";
import { uuidv4 } from "../../utils";
import { CircularProgressZenown, Loading } from "@zenonw/ui";


export interface DataMatrixReaderFromImageProps {
  useDefaultCamera?: boolean;
  onSuccessClose?: boolean;
  onClose: () => void;
  onError: () => void;
  onSuccess: (code: string) => void;
}

const useStyles = makeStyles({
});



export const DataMatrixReaderFromImage = (props: DataMatrixReaderFromImageProps) => {

  const classes = useStyles();
  const fileRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const uploadServices = new UploadService();

  /*
    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, [
      BarcodeFormat.DATA_MATRIX
    ]);
    const codeReader = new BrowserQRCodeReader()
    console.log('ZXing code reader initialized')
  */

  const handleChange = async (e: any) => {
    const file = e.target.files[0];

    if (e.target.files && e.target.files.length > 0) {

      setIsLoading(true);
      setHasError(false);
      console.log('file ', file);
      const uuid = uuidv4();

      const formData = new FormData();
      formData.append('uuid', uuid);
      formData.append('file', file);

      try{
        const uploadFile = await uploadServices.readQRCode(formData);

        if(uploadFile.data && uploadFile.data.text){
          props.onSuccess(uploadFile.data.text)
        }
        else if(uploadFile.data && uploadFile.data.code){
          props.onSuccess(uploadFile.data.code)
        }
        else {
          // props.onError()
          setHasError(true);
        }
      }
      catch(e)  {
        // props.onError()
        setHasError(true);
      };

      setIsLoading(false);

    }
  };

  const onButtonClick = (e: any) => {
    if(fileRef.current){
      fileRef.current.click();
    }
  }

  /*
  useEffect(()=> {

    if(imageRef && imageRef.current) {
      const img = document.getElementById('img2')
      console.log('imgElment ', img);
      console.log('starting deconde from image ref', imageRef.current);
      // @ts-ignore
      codeReader.decodeFromImageElement(imageRef.current)
        .then((result) => {
          console.log('sdsadas', result);
          //document.getElementById('result').textContent = result.text
        }).catch((err) => {
        console.error('error on reading ', err)
        //document.getElementById('result').textContent = err
      })
      // console.log(`Started decode for image from ${imageRef.current.src}`)
    }
  },[selectedImage, imageRef])*/

  if(isLoading) {
    return (
      <Grid item xs={12} style={{paddingTop:20}}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '20vh' }}>
          <Grid item xs={12}>
            <CircularProgressZenown colorprogress={'#33303C'} />
          </Grid>
        </Grid>
        <p style={{textAlign: 'center'}}>Please wait while we are processing your image</p>
      </Grid>
    )
  }

  return (
    <>
      <Grid item xs={12} style={{paddingTop:20}}>

        <div style={{ textAlign: 'center'}}>
          <Button style={{
            fontFamily: 'Satoshi-Variable',
            borderRadius: 20,
            border: '2px #5FCED0 solid',
            height:55,
            color: "#33303C",
            backgroundColor: 'transparent'
          }} variant={'contained'} onClick={onButtonClick}>
            <CameraAltIcon style={{paddingRight: 10}} />Scan the barcode </Button>
        </div>

        <input
          ref={fileRef}
          onChange={handleChange}
          multiple={false}
          type="file"
          accept={"image/*"}
          hidden
          capture={'environment'}
        />


        {/*selectedImage  &&
        <>
          <div style={{display:'inline', position:'relative'}}>
            <img
              // style={{width: '80%'}}
              ref={imageRef}
              id={'img2'}
              src={URL.createObjectURL(selectedImage)}
              alt="Thumb"
            />
            <img
              // style={{width: '80%'}}
              id={'img-to-decode'}
              alt="Thumb"
            />
          </div>
        </>
      */}

      </Grid>

      {hasError &&
        <Grid item xs={12} style={{paddingTop:20}}>
          <p style={{color: 'red'}}>Sorry, We didn't found the code in the picture</p>
        </Grid>
      }
    </>
  )

}


export default DataMatrixReaderFromImage;
