import './header.module.scss';

/* eslint-disable-next-line */
export interface HeaderProps {
  name: string;
}

export function Header(props: HeaderProps) {
  console.debug(props.name);
  return (
    <div>
      <h1>Welcome to Header! {props.name}</h1>
    </div>
  );
}

export default Header;
