import { Dialog, DialogTitle, Grid } from '@mui/material';
import { DialogTitleContainer } from '../../components/dialog/dialogTitle';
import { DialogContainer } from '../../components/dialog/dialogContainer';
import { useEffect, useState } from 'react';
import { RegisterStep1 } from './readProcess/RegisterStep1';
import { RegisterStep2 } from './readProcess/RegisterStep2';
import { RegisterStep3 } from './readProcess/RegisterStep3';
import makeStyles from '@mui/styles/makeStyles';

import Logo from '../../assets/reader/Logo SVG.svg';
import { DialogTitleCloseRightContainer } from '../../components/dialog/dialogTitleCloseRight';
import { RegisterStep4 } from './readProcess/RegisterStep4';

export interface ReadFormFields {
  name: string;
  email: string;
  sn: string;
  key: string;
  expenses: [];
  texts: [];
}

export interface ReadRegisterProcessProps {
  onClose: () => void;
  onHow: () => void;
  filesToSubmit: any;
}

const useStyles = makeStyles({
  logo: {
    padding: 10,
    height: 36,
    paddingTop: 25,
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
    color: '#573B32',
  },
  dot: {
    backgroundColor: '#F1F1F1 !important',
    width: '18px !important',
    height: '18px !important',
    '-webkit-clip-path':
      'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
    clipPath: 'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
    marginLeft: 10,
  },
  dotActive: {
    backgroundColor: '#F8B100 !important',
    width: '18px !important',
    height: '18px !important',
    '-webkit-clip-path':
      'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
    clipPath: 'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
    marginLeft: 10,
  },

  nextButton: {
    width: 325,
    height: 64,
    margin: '20px auto !important',
    padding: '21px 0 23px 0',
    // borderRadius: "32px !important",
    backgroundColor: '#F8B100 !important',
    color: '#573B32 !important',
    fontSize: 17,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: -0.2,
    textTransform: 'none',

    ['&:disabled']: {
      color: '#808185 !important',
      backgroundColor: '#F1F1F1 !important',
    },
  },

  powered: {
    color: '#2E3132',
    fontFamily: 'Avenir Next',
    fontSize: 10,
    paddingRight: 5,
  },
  rights: {
    color: '#808185',
    fontSize: 10,
    fontFamily: 'Roboto',
  },
});

export const ReadRegisterProcess = (props: ReadRegisterProcessProps) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});

  const [formValues, setFormValues] = useState<ReadFormFields>({
    name: '',
    email: '',
    key: '',
    sn: '',
    expenses: [],
    texts: [],
  });

  const handleChange = (input: string, e: any) => {
    const testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    console.log('input ', input);
    console.log('e ', e);

    switch (input) {
      case 'name':
        setFormValues({ ...formValues, name: e.target.value });
        break;
      case 'email':
        setFormValues({ ...formValues, email: e.target.value });
        break;
      case 'submitUserData':
        setFormValues({
          ...formValues,
          key: e.key,
          expenses: e.expenses,
          texts: e.texts,
        });
        setCompleted({ ...completed, 2: true });
        handleNext();
        break;
      case 'sn':
        setFormValues({ ...formValues, sn: e });
        setCompleted({ ...completed, 1: true });
        handleNext();
        break;
      default:
        break;
    }
    return;
  };

  // const handleNext = async (evt: any) => {
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const onCloseEvt = () => {
    props.onClose();
  };

  const registrationSteps = [
    <RegisterStep1
      values={formValues}
      onHow={props.onHow}
      onChange={handleChange}
      onPrevEvt={onCloseEvt}
    />,
    <RegisterStep4 values={formValues} onDone={onCloseEvt} />,
  ];

  useEffect(() => {}, []);

  return (
    <>
      <div>
        <img className={classes.logo} src={Logo} />
        {registrationSteps[activeStep]}
      </div>
    </>
  );
};

export default ReadRegisterProcess;
