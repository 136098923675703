import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import "./Stripe.scss";
export interface StripeProps { }

const stripePromise = loadStripe("pk_test_51KW1G0L9RDT5VTK3PpmG7fStpUu6aDLvD5NXVFEn7ra3a5ULrmomo7qA8sGHw12aiHbRsS0WbTuA9YIPGdoM4NXh00jzzwoIhU");

export function Stripe(props: StripeProps){

  const [clientSecret, setClientSecret] = useState("");
  const [valueToPay, setValueToPay] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("http://localhost:3000/api/create-paymentxx", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options:StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    console.log('handleSubmit valueToPay', valueToPay);
    fetch("http://localhost:3000/api/create-payment", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "insurance", value: valueToPay }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));

  }

  const onValueChange = async (e: any) => {
    console.log('onValueChange ', e.target.value);
    const valueToPay = parseInt(e.target.value) * 100;
    setValueToPay(valueToPay.toString());
  }
  return(
    <>
      {!clientSecret &&<>

        <form onSubmit={handleSubmit}>
          <p>Stripe component</p>
          <label>Enter the value to pay</label>
          <input id={"value"} onBlur={onValueChange}/>
          <hr />
          <button id="submit">
            <span id="button-text">
              Check out
            </span>
          </button>
        </form>
      </>


      }

      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  )
}

export default Stripe;
