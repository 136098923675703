import { useEffect, useRef, useState } from "react";
import {
  BarcodeFormat,
  BrowserMultiFormatReader,
  ChecksumException, DecodeHintType,
  FormatException,
  NotFoundException, Result,
} from "@zxing/library";
import { Button, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { usePrevious } from "../../utils";
import _ from "lodash";

export interface DataMatrixReaderFromCameraProsp {
  useDefaultCamera?: boolean;
  onSuccessClose?: boolean;
  onClose: () => void;
  onSuccess: (code: string) => void;
}

const useStyles = makeStyles({
  container:{
    position: 'relative',
    width: '100%',
    height: '60vh',
    overflow: 'hidden'
  },
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    width: '100%',
    height: '60vh',
  },
  video: {
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '150vh',
    position: 'absolute'
  }
});

export const DataMatrixReaderFromCamera = (props: DataMatrixReaderFromCameraProsp) => {

  const {onClose} = props;
  const classes = useStyles();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { useDefaultCamera, onSuccess } = props;
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [code, setCode] = useState('');
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [showVideo, setShowVideo] = useState(true);
  const [hasVideoDevices, setHasVideoDevices] = useState<boolean|undefined>(undefined);
  const prevStatus = usePrevious({hasVideoDevices});

  const hints = new Map();
  hints.set(DecodeHintType.POSSIBLE_FORMATS, [
    BarcodeFormat.DATA_MATRIX
  ]);

  // Initialize the code reader
  const codeReader = new BrowserMultiFormatReader(hints, 300);

  const decodeOnceFromVideoDevice = async (deviceId?: string, videoSource?: string | HTMLVideoElement):
    Promise<Result | null> =>{

    codeReader.reset();
    let videoConstraints: any;
    if (!deviceId) {
      getDevicesList()
      videoConstraints = {
        facingMode: 'environment'
      };
    }
    else {
      videoConstraints = {
        // deviceId: {  deviceId },
        facingMode: 'environment'
      };
    }

    const constraints: any = { video: videoConstraints, audio: false };

    return await decodeOnceFromConstraints(constraints, videoSource);
  }

  const decodeOnceFromConstraints =
    async (constraints: any, videoSource?: string | HTMLVideoElement): Promise<Result>  => {

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      return await codeReader.decodeOnceFromStream(stream, videoSource);
    }

  const  decodeContinuously = (selectedDeviceId: string) => {

    decodeOnceFromVideoDevice(
      selectedDeviceId,
      `video_${selectedDeviceId}`)
      .then((result)=> {
        console.log( ' result ', result);
        if(result === null){
          return
        }
        else {
          console.log('Found QR code!', result);
          setCode(result.getText());
          onSuccess(result.getText());
        }
      })
      .catch((err) => {
          if (err instanceof NotFoundException) {
            console.log('No QR code found.');
          }

          if (err instanceof ChecksumException) {
            console.log("A code was found, but it's read value was not valid.");
          }

          if (err instanceof FormatException) {
            console.log('A code was found, but it was in a invalid format.');
          }
        }
      );
    setSelectedDeviceId(selectedDeviceId);
  }

  const setupDevices = (videoInputDevices: MediaDeviceInfo[]) => {

    decodeContinuously(videoInputDevices[0].deviceId);
    // setSelectedDeviceId(videoInputDevices[0].deviceId);
    // setup devices dropdown
    if (videoInputDevices.length >= 1) {
      setVideoInputDevices(videoInputDevices);
    }
  }

  const getDevicesList =  async () => {
    await codeReader
      .listVideoInputDevices()
      .then((videoInputDevices: MediaDeviceInfo[])=> {

        const devicesListed = _.filter(videoInputDevices,(v) => {
          return v.label.includes('ack');
        })

        if(devicesListed.length > 0) {
          setupDevices(devicesListed)
        }
        else {
          setupDevices(videoInputDevices)
        }

      })
      .catch((err) => {
        console.error('err list inputs', err);
      });
  }

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    if(!canvas ) {
      console.log(`No canvas`);
    }
    else {
      let ctx: CanvasRenderingContext2D | null;
      if (!(ctx = canvas.getContext("2d"))) {
        console.log(`2d context not supported`);
      }
      else {

        const recWidth = ctx.canvas.width * .8
        const recHeight = ctx.canvas.height * .8
        const xPos = (ctx.canvas.width/2) - (recWidth/2);
        const yPos = (ctx.canvas.height/2) - (recHeight/2);
        ctx.strokeStyle = "#FF0F00";
        ctx.lineWidth = 3;
        ctx.rect(xPos,yPos,recWidth,recHeight)
        ctx.stroke()
      }
    }
  }

  useEffect(()=> {
    drawCanvas();
    getDevicesList()
  },[]);

  useEffect(() => {
      // setShowVideo(false);
      // decodeContinuously(selectedDeviceId);
      // setShowVideo(true);
      // console.log(`Started decode from camera with id ${selectedDeviceId}`);
    },
    [selectedDeviceId]
  );

  const onSelectChange = (selected: any) => {
    // setShowVideo(false);
    codeReader.reset();
    // setSelectedDeviceId(selected.target.value)
    decodeContinuously(selected.target.value);
    // setShowVideo(true);
  }

  const onCloseClick = () => {
    setShowVideo(false);
    codeReader.reset();
    setCode('');
    onClose();
  }

  return (
    <>
      <Grid container direction={'column'}
            style={{
              padding: '0 20px',
              minHeight: '90vh',
              backgroundColor:'#35313E' }}>
        <Grid item xs={12} style={{ color:'#FFF' }}>
          <span >Scan your code</span>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: '100%'}} hidden={!showVideo} >
          <div className={classes.container} >
            <canvas ref={canvasRef}className={classes.canvas}/>
            <video id={`video_${selectedDeviceId}`}
              // autoPlay={showVideo} playsInline={showVideo}
                   width="100%" height="60hv"
                   className={classes.video}
            />
          </div>
        </Grid>
        <Grid item xs={12} style={{ paddingTop:5, paddingBottom: 20}} hidden={videoInputDevices.length === 0}>
          <InputLabel id="sourceSelect-label"
                      style={{color: '#FFFFFF'}}
          >Change video source:</InputLabel>
          <Select
            labelId="sourceSelect-label"
            id="sourceSelect"
            value={selectedDeviceId}
            onChange={onSelectChange}
            style={{backgroundColor: '#FFFFFF'}}
          >
            {videoInputDevices.map((element, index) => (
              <MenuItem key={`video_${index}`} value={element.deviceId}>{element.label}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} style={{ paddingTop:5, color: 'white'}} hidden={videoInputDevices.length === 0}>

          <p>selected device: {selectedDeviceId}</p>

          {videoInputDevices.map((elem, index) => (
            <p key={ `_${index}_`}>deviceId: {elem.deviceId} label:{elem.label} kind:{elem.kind} </p>
          ))}
        </Grid>

        <Grid item xs={12}>
          <Button id="resetButton"
                  style={{
                    backgroundColor: '#FFFFFF',
                    color: '#35313E',
                    borderRadius: 30,
                    borderColor: '#FFFFFF',
                    textTransform: 'none',
                    height:57,
                  }}
                  onClick={onCloseClick}
                  variant={'outlined'}>
            Reset
          </Button>
        </Grid>
      </Grid>
    </>
  );
}


export default DataMatrixReaderFromCamera;
