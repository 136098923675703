import {
  Box, CircularProgress,
  circularProgressClasses,
  CircularProgressProps } from '@mui/material';

export interface CircularProgressZenProps {
  colorprogress?: string;
}
type Props =  CircularProgressZenProps & CircularProgressProps;

export const CircularProgressZenown = (props: Props) => {

  const { colorprogress } = props;

  return (
    <div style={{position:'relative', marginLeft:'auto', marginRight:'auto', width :'82px'}}>
      <Box sx={{position:'relative'}}>
        <CircularProgress
          variant="determinate"
          sx={{
            position: 'absolute',
            left: 0,
            color: (theme: any) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
          }}
          size={82}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: colorprogress? colorprogress: '#000000',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={82}
          thickness={4}
          {...props}
        />
      </Box>
    </div>
  );
}
