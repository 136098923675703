import { Input } from '@mui/material';
import { useEffect, useState } from 'react';
import { uuidv4 } from '../../utils';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

export interface ReactCodeInputProps {
  type: 'text' | 'number' | 'password' | 'tel';
  fields: number;
  fieldsSize?: number;
  isValid?: boolean;
  disabled?: boolean;
  value: string;
  style?: {};
  inputStyle?: {};
  inputStyleInvalid?: {};
  autoComplete?: string;
  autoFocus?: boolean;
  forceUppercase?: boolean;
  filterKeyCodes?: string[];
  filterChars?: string[];
  onChange?: (input: string) => void;
  filterCharsIsWhitelist?: boolean;
  pattern?: string;
  inputMode:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  name: string;
  touch?: (name: string) => void;
  untouch?: (name: string) => void;
  className?: string;
  placeholder?: string;
}

export const defaultInputStyle = {
  // fontFamily: 'monospace',
  MozAppearance: 'textfield',
  borderRadius: '6px',
  border: '1px solid',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
  margin: '6px',
  paddingLeft: 9,
  paddingRight: 8,
  width: '36px',
  height: '42px',
  fontSize: 20,
  boxSizing: 'border-box',
};

export const ReactCodeInputFields = (props: ReactCodeInputProps) => {
  const {
    fields,
    fieldsSize,
    onChange,
    type,
    isValid,
    disabled,
    filterKeyCodes,
    forceUppercase,
    style = {},
    inputStyle = {},
    inputStyleInvalid = {},
    autoFocus,
    autoComplete,
    pattern,
    inputMode,
    className,
    placeholder,
  } = props;

  let { value } = props;

  const [inputValue, setInputValue] = useState<string[]>([]);
  const [valueState, setValueState] = useState(value);

  if (forceUppercase) {
    value = value.toUpperCase();
  }
  const chunkString = (str: string, length: number) => {
    return str.match(new RegExp('.{1,' + length + '}', 'g'));
  };

  const splitCode = (codeString: string) => {
    const chunck = chunkString(codeString, fieldsSize || 1);
    if (!chunck) return [];
    let arrayObj: any[] = [];
    chunck.forEach((e) => {
      arrayObj.push(e);
    });
    return arrayObj;
  };

  useEffect(() => {
    setValueState(value);
    const inputArray = splitCode(value);
    while (inputArray.length < fields) {
      inputArray.push('');
    }
    setInputValue(inputArray);
  }, [value]);

  const textInput: any[] = [];

  const uuid = uuidv4();

  const handleBlur = (e: any) => {
    handleTouch(e.target.value);
  };

  const handleTouch = (value: string) => {
    const { touch, untouch, name } = props;

    if (typeof touch === 'function' && typeof untouch === 'function') {
      if (value === '') {
        touch(name);
      } else {
        untouch(name);
      }
    }
  };

  const handleChange = async (e: any) => {
    const {
      filterChars,
      filterCharsIsWhitelist,
      fields,
      forceUppercase,
      type,
    } = props;

    let value = String(e.target.value);

    console.log(`value: ${value}`);

    if (forceUppercase) {
      value = value.toUpperCase();
    }

    if (type === 'number') {
      value = value.replace(/[^\d]/g, '');
    }

    /** Filter Chars **/
    /* value = value.split('')
      .filter((currChar:string) => {
        if(!filterChars) return
        if (filterCharsIsWhitelist && filterChars.length > 0) {
          return filterChars.includes(currChar);
        }
        return !filterChars.includes(currChar);
      }).join('');
      */

    const last = value.charAt(value.length - 1);

    let fullValue = inputValue.join('').concat(last);

    let splitedCode = splitCode(fullValue);
    if (value !== '') {
      while (splitedCode.length < fields) {
        splitedCode.push('');
      }
      setValueState(fullValue);
      setInputValue(splitedCode);
    }

    splitedCode.map((s, i) => {
      if (textInput[i]) {
        textInput[i].value = s;
      }
      return false;
    });

    const newTargetId = Math.floor(fullValue.length / (fieldsSize || 1));

    if (newTargetId !== e.target.dataset.id) {
      const newTarget = textInput[newTargetId];
      if (newTarget) {
        newTarget.focus();
      }
    }

    if (props.onChange && fullValue) {
      props.onChange(fullValue);
    }

    handleTouch(fullValue);
  };

  const handleKeyDown = (e: any) => {
    const target = Number(e.target.dataset.id),
      nextTarget = textInput[target + 1],
      prevTarget = textInput[target - 1];

    let input, value;

    if (filterKeyCodes && filterKeyCodes.length > 0) {
      filterKeyCodes.map((item: string) => {
        if (item === e.keyCode) {
          e.preventDefault();
          return true;
        }
        return;
      });
    }

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault();
        textInput[target].value = '';
        input = inputValue.slice();
        input[target] = '';
        value = input.join('');

        setInputValue(input);
        setValueState(value);
        if (textInput[target].value === '') {
          if (prevTarget) {
            prevTarget.focus();
            prevTarget.select();
          }
        }
        if (onChange) {
          onChange(value);
        }
        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }
        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();
        if (nextTarget) {
          nextTarget.focus();
          nextTarget.select();
        }
        break;

      case UP_ARROW_KEY:
        e.preventDefault();
        break;

      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;

      case E_KEY: // This case needs to be handled because of https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
        if (e.target.type === 'number') {
          e.preventDefault();
          break;
        }
        break;

      default:
        break;
    }

    if (value) {
      handleTouch(value);
    }
  };

  const styles = {
    width: fieldsSize ? `${36 * fieldsSize}px` : defaultInputStyle.width,
    container: { display: 'inline-block', ...style },
    input: isValid ? inputStyle : inputStyleInvalid,
  };

  if (!className && Object.keys(inputStyle).length === 0) {
    Object.assign(inputStyle, {
      ...defaultInputStyle,
      width: fieldsSize ? `${24 * fieldsSize}px` : defaultInputStyle.width,
      color: 'black',
      backgroundColor: 'transparent',
      borderColor: 'lightgrey',
    });
  }

  if (
    !className &&
    isValid &&
    valueState.length === fields * (fieldsSize || 1)
  ) {
    Object.assign(inputStyle, {
      ...defaultInputStyle,
      width: fieldsSize ? `${24 * fieldsSize}px` : defaultInputStyle.width,
      color: 'black',
      backgroundColor: 'transparent',
      borderColor: '#64b948',
    });
  }
  if (!className && Object.keys(inputStyleInvalid).length === 0) {
    Object.assign(inputStyleInvalid, {
      ...defaultInputStyle,
      width: fieldsSize ? `${24 * fieldsSize}px` : defaultInputStyle.width,
      color: '#b94a48',
      backgroundColor: '#f2dede',
      borderColor: '#eed3d7',
    });
  }

  useEffect(() => {
    if (
      !className &&
      isValid &&
      valueState.length === fields * (fieldsSize || 1)
    ) {
      styles.input = {
        ...inputStyle,
        color: '#64b948 !important',
        backgroundColor: '#e4f2de',
        borderColor: '#ddeed3',
      };
    }
  }, [valueState]);

  if (disabled) {
    Object.assign(styles.input, {
      width: fieldsSize ? `${24 * fieldsSize}px` : defaultInputStyle.width,
      color: 'lightgrey',
      borderColor: 'lightgrey',
      backgroundColor: '#efeff1',
    });
  }

  return (
    <>
      <div className={className} style={styles.container}>
        {inputValue.map((value, i) => {
          return (
            <input
              ref={(ref) => {
                textInput[i] = ref;
              }}
              id={`${uuid}-${i}`}
              data-id={i}
              autoFocus={autoFocus && i === 0 ? true : false}
              value={value}
              key={`input_${i}`}
              type={type}
              min={0}
              max={9}
              maxLength={fieldsSize}
              style={{
                ...styles.input,
                maxWidth: '63px',
                boxShadow: 'none',
                //cursor: 'none',
              }}
              //@ts-ignore
              readonly="readonly"
              autoComplete={autoComplete}
              onFocus={(e) => {}}
              onBlur={(e) => handleBlur(e)}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              disabled={disabled}
              data-valid={isValid}
              pattern={pattern}
              inputMode={inputMode}
              placeholder={placeholder}
            />
          );
        })}
      </div>
    </>
  );
};

export default ReactCodeInputFields;
