import { Typography } from '@mui/material';
import ErrorIcon from './../../assets/insurance/error-icon.svg';

/* eslint-disable-next-line */
export interface ErrorMessageProps {
  message: string;
}

export function ErrorMessage(props: ErrorMessageProps) {
  return (
    <Typography
      variant="caption"
      color="#FF2D2D"
      sx={{
        fontWeight: '500',
        letterSpacing: '-0.3px',
        verticalAlign: 'middle',
        marginTop: '16.84px',
      }}
    >
      <img
        src={ErrorIcon}
        style={{
          width: '24px',
          height: '24px',
          verticalAlign: 'middle',
          marginRight: '12.24px',
        }}
      />
      {props.message}
    </Typography>
  );
}

export default ErrorMessage;
