import _ from "lodash";
import currency from "currency.js";


export function extractText(textDetection: any) {
  if(textDetection.TextDetections){
    return rekognitionDetectTextParser(textDetection);
  }
  else if(textDetection.Blocks){
    return analyzeDocumentTextParser(textDetection);
  }
  else {
    return expenseDocumentsTextParser(textDetection);
  }
}

export function rekognitionDetectTextParser(textDetection: any) {
  const boxes = textDetection.TextDetections
    .filter((textObj:any) => textObj.Type === 'LINE')
    .map((textObj:any) => {
      const bbox = textObj.Geometry.BoundingBox;

      const coord = [
        bbox.Left,
        bbox.Top,
        bbox.Width,
        bbox.Height
      ]
      return {
        coord,
        label: textObj.DetectedText,
        type: textObj.Type
      }
    });
  const texts = boxes.map((boxObj:any) =>  boxObj.label) ;

  return { boxes, texts  , expensesTable: null}
}

export function expenseDocumentsTextParser(textDetection: any){

  if(!textDetection || !textDetection.ExpenseDocuments || textDetection.ExpenseDocuments.length === 0){
    return {}
  }
  const expensesTable = textDetection.ExpenseDocuments[0].LineItemGroups
    .map((lineItemGroups: any) => {
      return lineItemGroups.LineItems.map((lineItems:any) => {
        const lineItemsObj = []
        let bbox
        lineItemsObj.push(lineItems.LineItemExpenseFields.map((expenseField: any)=> {
          const label = expenseField.LabelDetection ? expenseField.LabelDetection.Text : '';
          const type = expenseField.Type? expenseField.Type.Text : '';
          const value = expenseField.ValueDetection ? expenseField.ValueDetection.Text : '';
          if(type === 'EXPENSE_ROW') {
            bbox = expenseField.ValueDetection.Geometry.BoundingBox;
          }
          return {label, type, value}
        }))
        return { items: lineItemsObj[0], bbox };
      })
    })[0]

  const expenseBoxes = expensesTable.map((expenseRow: any) => {
    const coord = [
      expenseRow.bbox.Left,
      expenseRow.bbox.Top,
      expenseRow.bbox.Width,
      expenseRow.bbox.Height
    ]
    return { coord }
  })


  const summaryFields = textDetection.ExpenseDocuments[0].SummaryFields
    .map((summaryObj: any) => {
      const label = summaryObj.LabelDetection ? summaryObj.LabelDetection.Text : '';
      const type = summaryObj.Type? summaryObj.Type.Text : '';
      const value = summaryObj.ValueDetection ? summaryObj.ValueDetection.Text : '';
      return {label, type, value}
    })

  const texts = summaryFields;

  return { boxes: expenseBoxes, texts, expensesTable}

}

export function analyzeDocumentTextParser(textDetection: any) {

  const documentBlocks = textDetection.Blocks;
  // Reading documents lines
  const readDocumentBlocks = documentBlocks
    .filter((block: any) => block.BlockType === "PAGE")
    .map((pageBlock: any) => {
      const blockRelationships = pageBlock.Relationships;
      return blockRelationships.map((blockRelationship: any) => {
        return blockRelationship.Ids.map((id:any) => {
          return readBlockElementById(documentBlocks, id, "LINE")
        })
      })
    });

  // console.log("readDocumentBlocks ", readDocumentBlocks[0][0]);

  const readDocumentTables = documentBlocks
    .filter((block: any) => block.BlockType === "TABLE")
    .map((pageBlock: any) => {
      // console.log('pageBlock ', pageBlock);
      return pageBlock.Relationships.map((relationshipsList:any) => {
        return relationshipsList.Ids.map((id:any) => {
          return readBlockElementById(documentBlocks, id, "CELL")
        })
      });
    })


  const boxes = _.flatten(readDocumentBlocks[0][0]);

  const texts = boxes.map((boxObj:any) =>  `${boxObj.label}`) ;
  return { boxes, texts , expensesTable: null};
}

function readBlockElementById(blocksList:any, blockId:string, type:string){

  if(type === "LINE") {
    return blocksList
      .filter((block: any) => block.BlockType === type &&  block.Id === blockId)
      .map((linesBlock: any)=> {

        const typeObj = linesBlock.Text;
        const bbox = linesBlock.Geometry.BoundingBox;

        const coord = [
          bbox.Left,
          bbox.Top,
          bbox.Width,
          bbox.Height
        ]

        return {
          coord,
          label: typeObj
        }

        /*
        const linesRelationships = linesBlock.Relationships;
        linesBlock.map((linesRelationship: any) => {
          linesRelationship.Ids.map((id:any) => {
            return readBlockElementById(blocksList, id, "WORD")
          })
        })
        */
      })
  }

}


export function currencyToFloatParser(value: string) {

  return currency(value,{ decimal: ',' }).value;

}


export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export default extractText;
