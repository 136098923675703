import { useEffect, useRef, useState } from "react";
import {
  BarcodeFormat,
  BrowserDatamatrixCodeReader,
  BrowserMultiFormatReader,
  ChecksumException, DecodeHintType,
  FormatException,
  NotFoundException, Result,
  VideoInputDevice
} from "@zxing/library";
import { Button, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { usePrevious } from "../../utils";
import _ from "lodash";

export interface DataMatrixReaderProps {
  useDefaultCamera?: boolean;
  onSuccessClose?: boolean;
  onClose: () => void;
  onSuccess: (code: string) => void;
}

const useStyles = makeStyles({
  container:{
    position: 'relative',
    width: '100%',
    height: '80vh',
    overflow: "clip !important",
    marginBottom: 30
  },
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    width: '100%',
    height: '60vh',
  },
  video: {
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '150vh',
    position: "absolute",
    zIndex: 10
  }
});

export const DataMatrixReader = (props: DataMatrixReaderProps) => {

  const {onClose} = props;
  const classes = useStyles();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const hints = new Map();
  hints.set(DecodeHintType.POSSIBLE_FORMATS, [
    BarcodeFormat.DATA_MATRIX
  ]);

  // Initialize the code reader
  const codeReader = new BrowserMultiFormatReader(hints);

  const setupCameraSettings = async () => {

    try {

      // @ts-ignore
      const stream = await navigator.mediaDevices.getUserMedia(
        {  video: {
            // deviceId: "e5f470a2f532e7f8e673ee2c10fb643ae5457400bcd6af6739ba9c678b667486",
            facingMode: 'environment',
            width: {  ideal: 1280 },
            height: { ideal: 720 },
          } });
      const supports = navigator.mediaDevices.getSupportedConstraints();
      console.log('supports ', supports);

      // Get video track capabilities and settings.
      const [videoTrack] = stream.getVideoTracks();
      const capabilities = videoTrack.getCapabilities();
      const settings = videoTrack.getSettings();

      console.log('capabilities ', capabilities);
      console.log('settings ', settings);


      await codeReader.decodeOnceFromStream(stream, 'video_');

      // focusDistance
      // @ts-ignore
      // document.querySelector("video_").srcObject = stream;
    }
    catch (e: any) {
      console.log('error ', e);
      if(e.name === 'NotAllowedError') {
        console.log('show message need to allow camera');
      }
    }

  }

  useEffect(()=> {
    setupCameraSettings();
  },[]);



  const onCloseClick = () => {
    onClose();
  }

  return (
    <>
      <Grid container direction={'column'}
            style={{
              padding: '0 20px',
              minHeight: '90vh',
              backgroundColor:'#35313E' }}>
        <Grid item xs={12} style={{ color:'#FFF' }}>
          <span >Scan your code</span>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: '100%', height: 80}} >
          <div className={classes.container} >
            <canvas ref={canvasRef}className={classes.canvas}/>
            <video id={`video_`}
              // autoPlay={showVideo} playsInline={showVideo}
                   width="100%" height="120hv"
                   className={classes.video}
            />
          </div>
        </Grid>

        <Grid item xs={12} style={{zIndex: 1000}}>
          <Button id="resetButton"
                  style={{
                    backgroundColor: '#FFFFFF',
                    color: '#35313E',
                    borderRadius: 30,
                    borderColor: '#FFFFFF',
                    textTransform: 'none',
                    height:57,
                  }}
                  onClick={onCloseClick}
                  variant={'outlined'}>
            Reset
          </Button>
        </Grid>
      </Grid>
    </>
  );
}


export default DataMatrixReader;
