import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { namespaces } from '@zenown-insurance/i18n-service';
import StartImageReader from '../../assets/reader/home_image.png';
import CheckedIcon from '../../assets/insurance/checked-icon.svg';

export interface HowDoesWorksProps {
  onClose: (evt: any) => void;
}

export function HowDoesWorks(props: HowDoesWorksProps) {
  const { onClose } = props;
  const parse = require('html-react-parser');

  const history = useHistory();

  const { t } = { t: (s: string) => s };

  const closeHandler = () => {
    history.push('/');
  };
  const renderSections = () => {
    const totalSections = 6;
    const sectionTexts = [];

    for (let j = 1; j < totalSections + 1; j++) {
      const text = t(`howDoesWork.section${j}.text`).split('\n');
      sectionTexts.push(
        <div key={`_header_${j}`}>
          <h2 key={`_header_${j}`}>{t(`howDoesWork.section${j}.title`)}</h2>
          {text.map((line, k) => (
            <p key={`_text_${j}_${k}`}>{line}</p>
          ))}
        </div>
      );
    }

    return sectionTexts;
  };
  const insuranceFeatures = [
    {
      description:
        'Photograph the top of packaging above the barcode where the green box is located in the example above',
    },
    {
      description: 'Make sure the photo is taken in focus ',
    },
    {
      description: 'Ensure that your phone is close to the packaging',
    },

    {
      description: 'Submitting the same code twice will invalidate your points',
    },
  ];
  return (
    <>
      <Button
        variant={'outlined'}
        style={{
          marginTop: '20px',
          borderColor: 'rgba(68,71,74,0.06)',
          height: 32,
          width: 32,
          minHeight: 32,
          minWidth: 32,
          padding: 0,
          color: '#383A49',
          boxShadow: 'none',
        }}
        onClick={onClose}
      >
        <ArrowBackIcon sx={{ width: '19px', height: '15px' }} />
      </Button>
      <Divider sx={{ width: '100%', marginTop: '10px' }} />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={0}
        sx={{ minHeight: '98vh', maxWidth: '345px' }}
      >
        <Grid item sx={{ maxWidth: '345px' }}>
          <h1 style={{ fontSize: '32px' }}>Where to find the code?</h1>
          <p style={{ marginTop: '15px' }}>
            Scan the code number found underneath the pack or bundle with your
            phone’s camera:
          </p>
          <img
            src={StartImageReader}
            style={{
              width: '270px',
              height: '290px',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
            }}
          />
        </Grid>
        <Grid item style={{ padding: '0px 0px', minWidth: '345px' }}>
          <h1 style={{ fontSize: '16px' }}>Top tips:</h1>
          {insuranceFeatures.map((feat, index) => (
            <ListItem
              alignItems="center"
              key={index}
              sx={{ padding: '0px', marginTop: '15px' }}
            >
              <ListItemText
                sx={{ margin: '0' }}
                primary={
                  <>
                    <img
                      src={CheckedIcon}
                      style={{
                        height: '20px',
                        width: '20px',
                        marginRight: '10px',
                        verticalAlign: 'middle',
                      }}
                    />
                    <Typography
                      variant="caption"
                      sx={{
                        fontWeight: '500',
                        letterSpacing: '-0.3px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                    >
                      {feat.description}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default HowDoesWorks;
