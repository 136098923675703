import { Button, Grid } from '@mui/material';

export interface QuoteSectionProps {
  onSubmit: (journey: string) => void;
  showIdentityReader: boolean;
}

export const QuoteSection = (props: QuoteSectionProps) => {
  const { onSubmit, showIdentityReader } = props;

  const onClickEvt = () => {
    onSubmit('barcode');
  };

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="start"
      justifyContent="stretch"
      textAlign={'center'}
      style={{
        marginLeft: '0px',
        marginRight: '0px',
        width: '100%',
      }}
    >
      <Grid
        item
        xs={12}
        textAlign={'center'}
        style={{
          marginLeft: '0px',
          marginRight: '0px',
          paddingInline: '0px',

          width: '100%',
        }}
      >
        <Button
          fullWidth={true}
          style={{
            fontFamily: 'Satoshi-Variable',
            backgroundColor: '#35313E',
            color: '#FFFFFF',
            borderRadius: 30,
            textTransform: 'none',
            height: 57,
            padding: '0',
            paddingInline: '25px',

            marginTop: '50px',
          }}
          variant={'contained'}
          onClick={onClickEvt}
        >
          Scan track & trace code
        </Button>
      </Grid>
    </Grid>
  );
};
