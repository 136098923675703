import { Button,  Grid,  } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Check from '../../../assets/reader/thanks-circle.svg'





const useStyles = makeStyles({

  textHeader: {
    color: '#33303C',
    fontFamily: 'Satoshi-Variable',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '120.19%'
  },

  text: {
    color: '#33303C',
    fontFamily: 'Satoshi-Variable',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '22px'
  }
});

export interface RegisterStep4Props {
  values: any;
  onDone:  () => void;
}


export const RegisterStep4 = (props: RegisterStep4Props) => {

  const classes = useStyles();
  const { onDone } = props;

  const onClickEvt = (evt: any) => {
    onDone();
  }

  return (
    <>
      <Grid container direction={'row'}
            spacing={0}
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '70vh', textAlign: 'center' }}

      >
        <Grid item xs={12}>
          <Grid container direction={'row'}
                spacing={3}
                alignItems="center"
                justifyContent="center"
                style={{ textAlign: 'center' }}

          >
            <Grid item xs={12}>
              <img src={Check} style={{width: 48}}/>
            </Grid>
            <Grid item xs={12}>
              <span className={classes.textHeader}>Thank you!</span>
            </Grid>
            <Grid item xs={12}>
              <span className={classes.text}>
                You have successfully registered your device.
              </span>
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={onClickEvt}
                style={{
                  fontFamily: 'Satoshi-Variable',
                  backgroundColor: '#35313E',
                  color: '#FFFFFF',
                  borderRadius: 30,
                  textTransform: 'none',
                  height:57,
                  padding: '0 10px'
                }} variant={'contained'} >Done</Button>
            </Grid>
          </Grid>
        </Grid>


      </Grid>

    </>

  )
}
